import React from "react"
import _ from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ListItem, UnorderedList } from "@chakra-ui/react"
import { Link } from "gatsby"
import Profile from "../components/organisms/profile"
import MainHeaderBar from "../components/atom/main-header-bar"
import { AllTagDefinition } from "../params/all-tag-difinition"
import { Helmet } from "react-helmet"
import HatenaArticles from "../components/organisms/hatena-articles"

const Tags = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
        tagsGroup: allMdx {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `
  )

  const siteTitle = data.site.siteMetadata?.title || `Title`

  const sideBarContents = [<Profile />, <HatenaArticles />]

  const tags = data.tagsGroup.group

  return (
    <Layout
      location={location}
      title={siteTitle}
      sideBarContents={sideBarContents}
    >
      <Seo title="タグ一覧"></Seo>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <MainHeaderBar as="h1">タグ一覧</MainHeaderBar>
      <UnorderedList listStyleType="none">
        {tags.map((tag) => {
          // タグの英名を取得する（gatsby-node.jsと同じ処理である必要があります）
          // タグの英名を取得する（gatsby-node.jsと同じ処理である必要があります）============
          // この処理は廃止。
          // const tagDefinition = AllTagDefinition.filter(
          //   (item) => item.name === tag.fieldValue
          // )
          // const tagSlug =
          //   tagDefinition.length === 0 ? tag.fieldValue : tagDefinition[0].slug // 定義に無かった場合（filterした結果が空配列）は日本語のままslugにする。
          // =====================================================================
          const tagSlug = _.kebabCase(tag.fieldValue)
          return (
            <Link to={`/tags/${tagSlug}`}>
              <ListItem
                p={1}
                borderBottom="1px"
                borderColor="orange.100"
                mb={1}
              >{`${tag.fieldValue} (${tag.totalCount})`}</ListItem>
            </Link>
          )
        })}
      </UnorderedList>
    </Layout>
  )
}

export default Tags
